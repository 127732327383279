<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 align-items-center justify-content-center">
      <!-- Reset Password -->
      <b-col
        lg="5"
        md="8"
        sm="12"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 mx-2"
      >
        <b-col
          sm="8"
          md="10"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h1"
            class="font-weight-bold mb-1 text-center"
          >
            Reset Password
          </b-card-title>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required|password|min:8"
                  vid="Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="models.newPassword"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="password1FieldType"
                      name="login-password"
                      placeholder="Masukkan password"
                      @keydown.space.prevent
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- confirm password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="reset-password-confirm">Confirmation Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="vars.confirmPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="Masukkan password"
                      @keydown.space.prevent
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Submit
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <span>&nbsp;Kembali ke halaman Login</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import api from '@/utils/api'
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  metaInfo: {
    title: 'Reset Password',
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      models: {
        newPassword: '',
        resetPasswordToken: '',
      },
      vars: {
        confirmPassword: '',
      },
      // validation rulesimport store from '@/store/index'
      required,
      email,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    const { token } = this.$route.query
    if (token) {
      this.models.resetPasswordToken = token
    } else {
      this.$router.push('/login')
    }
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$axios.post(api.resetPassword, this.models)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success change password',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
            })
            .then(() => {
              if (localStorage.getItem('token')) {
                this.$store.dispatch('user/logout')
                localStorage.removeItem('token')
              }
            })
            .then(() => {
              // this.$router.push('/login')
              window.location.href = '/login'
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.response.data.message,
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
